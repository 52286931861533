.processing-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.payment-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 500px;
  margin: auto;
  padding-top: 64px;
  overflow: hidden;

  @media (max-width: 663px) {
    padding-top: 32px;
  }

  .block {

	background-color: var(--control-tver);
    border-radius: 32px;

	display: flex;
	flex-direction: column;
	align-items: center;

	row-gap: 20px;
	padding: 20px;

	.text {
		padding: 0 16px;

		.title {
			color: var(--text-vladimir);
			font-family: 'MTSWideMedium';
			margin: 0 0 8px 0;
			font-size: 24px;
			font-weight: 500;
		}
		
		.description {
			color: var(--text-vladimir);
			font-family: 'MTSCompactRegular';
			font-weight: 400;
			margin: 0;
			max-width: 360px;
			font-size: 14px;
			font-style: normal;
			line-height: 18px;
		}
	}	
	
	.actions {
		display: flex;
		column-gap: 12px;
		color: var(--text-volgograd);
	}
  }

  

  .offer {
	margin-top: 20px;
    max-width: 320px;
    overflow: hidden;
    line-height: 1.2;
    font-size: 12px;
    color: var(--web-primary-gray);

    a {
      color: var(--web-primary-bright-blue);
    }
  }
}