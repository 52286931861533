.content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 24px;
  padding-top: 106px;
  max-width: 1104px;
  width: 100%;

  .titleWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h2 {
      text-align: left;
    }
    
    h3 {
      display: none;
      
      @media (max-width: 1199px) {
        display: unset;
        font-size: 14px;
        line-height: 20px;
        color: #e6e6e6;
      }
    }
  }

  .subscriptionList {
    display: flex;
    flex-direction: column;
    gap: 68px;

    @media (max-width: 1199px) {
      gap: 40px;
    }

    @media (max-width: 767px) {
      gap: 32px;
    }

    .sub {
      height: 384px;
      position: relative;

      @media (max-width: 1199px) {
        height: 269px;
      }

      @media (max-width: 767px) {
        height: 144px;
      }

      .contentWrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 474px;
        position: relative;
        z-index: 1;
        padding: 40px;
        justify-content: space-between;
          
        @media (max-width: 1199px) {
          padding: 24px;
        }
                  
        @media (max-width: 767px) {
          padding: 16px;
        }

        .text {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .description {
            font-size: 17px;
            line-height: 24px;
            font-weight: 400;

            @media (max-width: 1199px) {
              display: none;
            }
          }
        }

        button {
          width: fit-content;
        }
      }

      .layer {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        border-radius: 30px;
        height: 100%;
        width: 100%;
      }

      .badge {
        position: absolute;
        top: 40px;
        right: 40px;

        @media (max-width: 1199px) {
          top: 24px;
          right: 24px;
        }
                  
        @media (max-width: 767px) {
          top: 16px;
          right: 16px;
        }
      }
    }
  }

  footer {
    width: 100%;
    padding: 44px 0px 44px 0px;
    gap: 48px;
    opacity: 0px;

    //styleName: Paragraph/P4 Regular Comp;
    font-family: MTS Compact;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
}