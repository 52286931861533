.select {
  &__input {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    padding: 0 16px;
    cursor: pointer;
    border-radius: 6px;
    background-color: var(--bg-tetriary);

    .icon {
      margin-right: 8px;
    }

    .dropdown-icon {
      position: absolute;
      right: 16px;
      width: 16px;
      height: 16px;
      transition: {
        property: transform;
        duration: .2s;
        timing-function: ease-out;
      }
      transform: rotate(-90deg);;
    }

    &.open .dropdown-icon {
      transform: rotate(90deg);
    }
  }

  &__options {
    color: var(--text-form-primary);
    position: absolute;
    padding: 8px 0;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    border-radius: 4px;
    background: var(--text-inverted);
	z-index: 1;

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      cursor: pointer;
      padding: 12px 8px;
      transition: background-color .15s ease-in;

      .icon {
        margin-right: 8px;
      }
      .label {
        line-height: 1;
      }
      .tooltip {
        font-size: 14px;
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &:hover, &.selected {
        background: var(--text-tetriary);
      }
    }
  }

  &__wrapper {
    position: relative;
  }
}
