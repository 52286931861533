.kion-button {
  font-family: MTSSans, Arial, sans-serif;
  background: var(--bg-accent);
  color: var(--text-form-primary);
  border: 1px solid var(--bg-accent);
  transition: {
    property: border-color, background-color;
    duration: .3s;
    timing-function: ease-in-out;
  };
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  outline: none;

  &.confirm {
	background: var(--control-surgut);
	color: var(--text-volgograd);
	border: none;

	border-radius: 16px;
	padding: 16px 20px;
	line-height: 0;

	font-family: 'MTSWideMedium';
	letter-spacing: 1px;
	text-transform: uppercase;

	&:not(.negative) {
	  padding: 18px 58px;  
	}
  }

  &:hover, &:focus, &:active {
    background: var(--bg-accent-hover);
    border-color: var(--bg-accent-hover);

	&.confirm:not(.negative) {
		background: var(--control-surgut);
		opacity: 0.15;
	}
  }
  
  &.negative {
    color: var(--text-inverted);
    background: var(--web-tetriary-blue);
    border-color: var(--web-tetriary-blue);

	&.confirm {
		background: var(--control-rostov);
	}
  }
  &[disabled], &.disabled {
    cursor: default;
    background: var(--bg-tetriary);
    color: var(--text-tetriary);
    border-color: var(--bg-tetriary);
  }
}